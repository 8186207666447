@use 'styles/globals/placeholders' as *;
@use 'styles/globals/mixins/font';
@use 'styles/globals/breakpoints';



.block{
    display: flex;
    margin: 3rem 0 0 0;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    @media screen and (max-width: breakpoints.$screen-md){
        margin: 0
    }
}

.title{
    width: 55%;
    margin: 2rem 0;
    flex: 1 0 auto;
    font-weight: 400;
    line-height: 1.4;
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;

    &_quick{
        margin-top: 0;
    }
}

.amount{
    width: 45%;
    margin: 2rem 0;
    flex: 1 0 auto;
    display: flex;
    justify-content: flex-end;
    line-height: 1.2;

    &_quick{
        margin-top: 0;
    }
}

.grandTotal{
    margin: 0
}

.grandTotalHeading{
    @extend %h4;
    @include font.dynamic-font(18, 22);
    margin: 0 !important;
    font-weight: 800;
}



