
.block {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    border-top: 1px solid var(--dove);


    & img {
        width: 100%;
        object-fit: contain;
    }
}

