@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';


.block {
    padding: 0;
    margin-bottom: 2rem;
}

.row {
    margin: 2rem 0 0 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    &::after {
        content: "";
        flex: auto;
    }
}

.item {
    width: calc(100%/2);
    padding-right: 2rem;
    list-style-type: none;
    margin-bottom: 2rem;

    &:nth-last-child(-n + 2){
        margin: 0
    }
}

.link {
    display: flex;
    flex-direction: row;
    color: inherit;
    align-items: center;

    @media screen and (max-width: breakpoints.$screen-md) {
        flex-direction: column;
    }
}

.image {
    flex: 1 0 40;
    max-height: 75px;
    object-fit: contain;
}

.details {
    display: flex;
    padding-left: 1rem;
    vertical-align: top;
    flex-direction: column;

    @media screen and (max-width: breakpoints.$screen-md) {
        padding: 1rem 0 0 0;
    }
}

.title {
    @include font.dynamic-font(11, 14);
    line-height: 1.2;
    font-weight: normal;
    margin-bottom: 1rem;
}

.price {
    margin: 0;
    @include font.dynamic-font(11, 14);
    display: flex;
    align-items: baseline;
}

.perUnit{
    padding-left: .25rem;
}

