@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';


.block {
    padding: 0;
}

.row {
    margin: 2rem 0 0 0;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;

    &::after {
        content: "";
        flex: auto;
    }
}

.item {
    width: calc(100%/2);
    padding-right: 2rem;
    list-style-type: none;
    margin-bottom: 2rem;
}

.link {
    display: flex;
    flex-direction: column;
    color: inherit;
    text-align: left;

    &:hover{
        text-decoration: none;
    }

    @media screen and (max-width: breakpoints.$screen-md) {
        flex-direction: column;
    }
}

.image {
    flex: 1 0 60%;
    max-height: 75px;
    object-fit: contain;
}

.details {
    display: flex;
    padding-left: 1rem;
    vertical-align: top;
    flex-direction: column;

    @media screen and (max-width: breakpoints.$screen-md) {
        padding: 1rem 0 0 0;
    }
}

.heading{
    font-size: 1.1rem;
    text-transform: uppercase;
    line-height: 1;
    color: #999;
}

.text{
    font-size: 1.1rem;
    margin: 0;
    line-height: 1.2;
    color: #777;

    em{
        font-weight: bold;
    }
}

.title {
    @include font.dynamic-font(14, 16);
    font-weight: 800;
    margin-bottom: .5rem;
    line-height: 1.3;
}

.price {
    margin: 0;
    @include font.dynamic-font(11, 14);
    display: flex;
    align-items: baseline;
}

.perUnit{
    padding-left: .25rem;
}

