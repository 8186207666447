
.block {
    padding: 2rem 2rem 0 2rem;
}

.footer {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
}

.show {
    display: block !important;
}
