

.list {
    margin-left: 0;
    position: relative;
    list-style-type: none;
}

.item{
    color: #2E303B;
    padding: .5rem 0;
    margin-bottom: .5rem;
    border-bottom: 1px solid var(--dove);
    display: flex;
    align-items: center;
    justify-content: space-between;
    // text-transform: capitalize;

    &:last-child{
        border: none
    }

    &::after {
        // content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%232E303B' xml:space='preserve'%3E%3Cpath d='M10.4 12l5.3-5.3c0.4-0.4 0.4-1 0-1.4s-1-0.4-1.4 0l-6 6c-0.4 0.4-0.4 1 0 1.4l6 6c0.2 0.2 0.5 0.3 0.7 0.3s0.5-0.1 0.7-0.3c0.4-0.4 0.4-1 0-1.4l-5.3-5.3z'%3E%3C/path%3E%3C/svg%3E");
        content: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' fill='%232E303B' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.4 8h7.6c0.6 0 1-0.4 1-1s-0.4-1-1-1h-10c-0.1 0-0.3 0-0.4 0.1-0.2 0.1-0.4 0.3-0.5 0.5-0.1 0.1-0.1 0.3-0.1 0.4v10c0 0.6 0.4 1 1 1s1-0.4 1-1v-7.6l8.3 8.3c0.2 0.2 0.5 0.3 0.7 0.3s0.5-0.1 0.7-0.3c0.4-0.4 0.4-1 0-1.4l-8.3-8.3z'%3E%3C/path%3E%3C/svg%3E");
        width: 24px;
        height: 24px;
        display: block;
    }
}


.link{
    display: block;
    flex: 1 0 auto;
    color: inherit;

    &:hover {
        text-decoration: none;
    }
}

