@use 'styles/globals/mixins/font';

.block{
    display: flex;
    margin: 0;
    align-items: baseline;
}

.value{
    font-weight: 800;
    @include font.dynamic-font(20, 26);
    line-height: 1;
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
}

.rrp{
    color: var(--primary-color); 
    opacity: 0.5;
    @include font.dynamic-font(16, 20);
    margin-right: 1rem;
}    

