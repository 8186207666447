
.block {
    border-bottom: 1px solid var(--dove);
    padding: 1rem 1rem .5rem 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0rem;
    
    & em{
        font-weight: normal;
    }
}

.link {
    margin: 0;
    text-transform: none;
    display: flex;
    align-items: center;
    line-height: 1;
    border-bottom: none;
    font-weight: 800;

    &:hover{
        text-decoration: underline;

        & svg{
            fill: inherit;
        }
    }

    & svg{
        margin-top: 2px;
        fill: var(--link_color);
    }
}
