@use 'styles/globals/breakpoints';
@use 'styles/globals/mixins/font';



.block{
    padding: 0 0 2rem 0;

    @media screen and (max-width: breakpoints.$screen-md){
        padding: 2rem 0 0 0
    }
}

.controlsColumn{
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
}

.qtyControls{
    display: flex;
    align-content: center;
    align-items: center;
    flex: 1 0 auto
}

.qtyLabel{
    text-transform: uppercase;
    margin-right: 2rem;
    line-height: 1;
    @include font.dynamic-font(18, 22);
    font-weight: 800;
}

.itemControls{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    width: 100%;
    background-color: var(--mist);
    padding: 1rem 2rem;
}

.itemHelpers{
    // margin-top: 2rem;
    // border-top: 2px solid $dove;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.tradeCntr{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    width: 100%;
}

.each{
    margin: 0 2rem;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    text-align: right;
}

.shipping{
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    & > div{
        width: 50%;
    }
}

.shippingFull{
    & > div{
        width: 100%;
    }
}

.noReturns{
    margin-bottom: 0 !important;
    padding-left: 2rem !important;
    padding-right: 1rem !important;
}


.itemPrice{
    justify-content: flex-end;
}

.itemCoverage{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
}


