
.list{
    margin: 2rem 0 0 0;
}

.item {
    display: inline-block;
    width: 8rem;
    height: 9rem;
    background-color: #F0F0F0;
    border: 1px solid F0F0F0;
    margin-right: 1.5rem;
}
